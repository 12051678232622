export type UseFieldControl = {
    focus: () => void
    setCursorPosition: (position: number) => void
}

export const useFieldControl = (
    targetRef: Ref<HTMLInputElement | HTMLTextAreaElement | ReturnType<typeof defineComponent>>,
    options: {
        nestedRefName?: string
        autofocus?: boolean
        startTyping?: boolean
        trap?: boolean
        disabled?: Ref<boolean> | (() => boolean)
    } = { autofocus: true },
): UseFieldControl => {
    const { document } = getClientContext()

    const elRef = ref<HTMLInputElement | HTMLTextAreaElement>()

    watch(targetRef, (target) => {
        elRef.value = options?.nestedRefName ? target?.$refs[options.nestedRefName] : target

        if (!elRef.value) {
            return
        }

        const isDisabled = (): boolean => {
            if (elRef.value.disabled || (elRef.value === document.activeElement)) {
                return true
            }

            if (!options || !('disabled' in options)) {
                return false
            }

            if (typeof options.disabled === 'function') {
                return options.disabled()
            }

            return options.disabled.value
        }

        if (options?.autofocus && !isDisabled()) {
            elRef.value.focus()
        }

        if (options?.trap) {
            elRef.value.onblur = () => !isDisabled() && elRef.value.focus()
        }

        if (options?.startTyping) {
            onStartTyping(() => !isDisabled() && elRef.value.focus())
        }
    }, { immediate: true })

    return {
        focus() {
            setTimeout(() => elRef.value?.focus(), 0)
        },
        setCursorPosition(position) {
            setTimeout(() => {
                if (elRef.value) {
                    elRef.value.focus()

                    elRef.value.selectionStart = elRef.value.selectionEnd = position
                }
            }, 0)
        },
    }
}
